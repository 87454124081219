<template>
	<div class="admin-container detail-container">
		<Head />
		<div class="lab-content">
			<div class="container container-lg">
				<el-row :gutter="20">
					<el-col :xs="24" :sm="24" :md="16" :lg="16">
						<div class="left-container">
							<div class="title">
								<img :src="detail.smallImageUrl" alt="">
								<div class="title-text">
									<h1>{{detail.title}}</h1>
									<p>{{detail.detail}}</p>
								</div>
							</div>
							<div class="content-wrap">
								<div class="content">
									<div class="content-text" v-html="detail.content"></div>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :xs="24" :sm="24" :md="8" :lg="8">
						<div class="right-container">
							<p class="base">{{$t('baseinfo')}}</p>
							<div class="progress">
								<div class="progress-text">
									<span class="gray">{{$t('labSpeen')}}</span>
									<span class="gray">{{$t('labNumber')}}</span>
								</div>
								<el-progress :percentage="detail.progress" style="width: 100%;" status="warning"
									:stroke-width="5" :show-text="false"></el-progress>
								<div class="progress-text">
									<span>{{detail.progress | fixedScale(2)}}%</span>
									<span>{{detail.totalSupply | fixedScale(detail.amountScale)}} {{detail.unit}}</span>
								</div>
							</div>
							<div class="info">
								<div class="info-item">
									<p class="title gray">{{$t('wtth8')}}</p>
									<p class="value" v-if="detail.step == 0">{{$t('labTab2')}}</p>
									<p class="value" v-if="detail.step == 1">{{$t('labTab3')}}</p>
									<p class="value" v-if="detail.step == 2">{{$t('tokendistribute')}}</p>
									<p class="value" v-if="detail.step == 3">{{$t('labTab5')}}</p>
								</div>
								<div class="info-item">
									<p class="title gray">{{$t('activitytype')}}</p>
									<p class="value" style="color:#F90;" v-if="detail.type==1">{{$t('labType1')}}
										<router-link :to="'/helpdetail?cate=1&id=33&cateTitle='+$t('footmeun2_2')" target="_blank">
											{{$t('ruledetail')}}
										</router-link>
									</p>
									<p class="value" style="color:#F90;" v-if="detail.type==2">{{$t('labType2')}}
										<router-link :to="'/helpdetail?cate=1&id=32&cateTitle='+$t('footmeun2_2')" target="_blank">
											{{$t('ruledetail')}}
										</router-link>
									</p>
									<p class="value" style="color:#F90;" v-if="detail.type==3">{{$t('labType3')}}
										<router-link :to="'/helpdetail?cate=1&id=36&cateTitle='+$t('footmeun2_2')" target="_blank">
											{{$t('ruledetail')}}
										</router-link>
									</p>
									<p class="value" style="color:#F90;" v-if="detail.type==4">{{$t('labType4')}}
										<router-link :to="'/helpdetail?cate=1&id=37&cateTitle='+$t('footmeun2_2')" target="_blank">
											{{$t('ruledetail')}}
										</router-link>
									</p>
									<p class="value" style="color:#F90;" v-if="detail.type==5">{{$t('labType5')}}</p>
									<p class="value" style="color:#F90;" v-if="detail.type==6">{{$t('labType6')}}</p>
								</div>
								<div class="info-item" v-if="detail.type == 6">
									<p class="title gray">{{$t('releaseType')}}</p>
									<p class="value" style="color:#F90;" v-if="detail.releaseType==0">
										{{$t('releaseType0')}}
									</p>
									<p class="value" style="color:#F90;" v-if="detail.releaseType==1">
										{{$t('releaseType1')}}
									</p>
								</div>

								<div class="info-item" v-if="detail.type == 6">
									<p class="title gray">{{$t('releaseDetail')}}</p>
									<p class="value" style="color:#F90;">{{detail.lockedDays}} {{$t('lock')}}
										<span v-if="detail.lockPeriod == 0">{{$t('lockday')}}</span>
										<span v-if="detail.lockPeriod == 1">{{$t('lockweek')}}</span>
										<span v-if="detail.lockPeriod == 2">{{$t('lockmonth')}}</span>
										<span v-if="detail.lockPeriod == 3">{{$t('lockyear')}}</span>
									</p>
								</div>

								<div class="info-item" v-if="detail.type == 6 && detail.releaseType == 1">
									<p class="title gray">{{$t('releasePercent')}}</p>
									<p class="value" style="color:#F90;">{{detail.releasePercent * 100}} %</p>
								</div>

								<div class="info-item" v-if="detail.type == 6">
									<p class="title gray">{{$t('lockFee')}}</p>
									<p class="value" style="color:#F90;">{{detail.lockedFee}} {{detail.lockedUnit}}</p>
								</div>

								<div class="info-item" v-if="detail.type == 6">
									<p class="title gray">{{$t('releaseTimes')}}</p>
									<p class="value" style="color:#F90;">{{detail.releaseTimes}} {{$t('times')}}</p>
								</div>

								<div class="info-item">
									<p class="title gray">{{$t('labNumber')}}</p>
									<p class="value">
										{{detail.totalSupply | fixedScale(detail.amountScale)}}<span>{{detail.unit}}</span>
									</p>
								</div>
								<div class="info-item" v-if="detail.type != 3 && detail.type != 6">
									<p class="title gray">{{$t('publishprice')}}</p>
									<p class="value">{{detail.price | fixedScale(detail.priceScale)}}
										<span>{{detail.acceptUnit}}</span>
									</p>
								</div>
								<div class="info-item" v-if="detail.type != 5 && detail.type != 6">
									<p class="title gray">{{$t('lctxt')}}</p>
									<p class="value">{{detail.unit}}</p>
								</div>
								<div class="info-item">
									<p class="title gray" v-if="detail.type != 3 && detail.type != 6">{{$t('lctxt2')}}
									</p>
									<p class="title gray" v-if="detail.type == 3 || detail.type == 6">{{$t('holdcoin')}}
									</p>
									<p class="value">{{detail.acceptUnit}}</p>
								</div>
								<div class="info-item">
									<p class="title gray">{{$t('limittimes')}}</p>
									<p class="value" v-if="detail.limitTimes > 0">{{detail.limitTimes}}次</p>
									<p class="value" v-else>{{$t('unlimited')}}</p>
								</div>
								<div class="info-item">
									<p class="title gray">{{$t('limitamount')}}</p>
									<p class="value" v-if="detail.minLimitAmout > 0 && detail.maxLimitAmout > 0">
										{{detail.minLimitAmout | fixedScale(detail.amountScale)}} ~
										{{detail.maxLimitAmout | fixedScale(detail.amountScale)}}
									</p>

									<p class="value" v-if="detail.minLimitAmout > 0 && detail.maxLimitAmout == 0"> ≥
										{{detail.minLimitAmout | fixedScale(detail.amountScale)}}
									</p>

									<p class="value" v-if="detail.minLimitAmout == 0 && detail.maxLimitAmout > 0"> ≤
										{{detail.maxLimitAmout | fixedScale(detail.amountScale)}}
									</p>

									<p class="value" v-if="detail.minLimitAmout == 0 && detail.maxLimitAmout == 0">
										{{$t('unlimited')}}
									</p>
								</div>

								<div class="info-item" v-if="detail.leveloneCount > 0">
									<p class="title gray">{{$t('leveloneCount')}}</p>
									<p class="value" v-if="detail.leveloneCount > 0">{{detail.leveloneCount}}</p>
									<p class="value" v-else>{{$t('unlimited')}}</p>
								</div>
								<div class="info-item">
									<p class="title gray">{{$t('labStart')}}</p>
									<p class="value">{{detail.startTime | datefmt('YYYY-MM-DD HH:mm:ss')}}</p>
								</div>
								<div class="info-item">
									<p class="title gray">{{$t('labEnd')}}</p>
									<p class="value">{{detail.endTime | datefmt('YYYY-MM-DD HH:mm:ss')}}</p>
								</div>
							</div>
							<!-- 仅自由申购类型/矿机显示已兑/剩余数量 -->
							<div class="tips" v-if="detail.type == 4 || detail.type == 5">
								<div class="left-tip">
									<p class="title gray">{{$t('alreadyamount')}}</p>
									<p>{{detail.tradedAmount | fixedScale(detail.amountScale)}} {{detail.unit}}</p>
								</div>
								<div class="right-tip">
									<p class="title gray">{{$t('leftamount')}}</p>
									<p>{{(detail.totalSupply - detail.tradedAmount) | fixedScale(detail.amountScale)}}
										{{detail.unit}}
									</p>
								</div>
							</div>
							<!-- 持仓瓜分类型显示冻结参与者 -->
							<div class="tips flexcolumn" v-if="detail.type == 3">
								<div class="tipsline1">
									<div class="left-tip">
										<p class="title gray">{{$t('myalreadyholdamount')}}</p>
										<p>{{myHoldAmount | fixedScale(detail.amountScale)}} {{detail.acceptUnit}}</p>
									</div>
									<div class="right-tip">
										<p class="title gray">{{$t('alreadyholdamount')}}</p>
										<p>{{detail.freezeAmount | fixedScale(detail.amountScale)}}
											{{detail.acceptUnit}}
										</p>
									</div>
								</div>
								<div class="tipsline2" v-if="isLogin">
									{{$t('currentdivided')}}：{{myHoldAmount | holdPercent(detail.freezeAmount, detail.totalSupply)}}
									{{detail.unit}}
								</div>
							</div>
							<p v-if="detail.type == 3" class="hold-tips">*{{$t('holdtips')}}</p>
							<div class="do-activity"
								v-if="isLogin && (detail.type==3 || detail.type==4 || detail.type==5 || detail.type==6)">
								<div class="do-left">
									<p class="p-title" v-if="detail.type==3 || detail.type == 6">
										{{$t('inputholdamount')}}
									</p>
									<p class="p-title" v-if="detail.type==4">{{$t('inputamount')}}</p>
									<p class="p-title" v-if="detail.type==5">{{$t('inputminingamount')}}</p>
									<el-input :placeholder="$t('placeholder2')" type="number" v-model="attendAmount">
										<template slot="suffix">
											<span v-if="detail.type==3">{{detail.acceptUnit}}</span>
											<span v-else-if="detail.type==6">{{detail.lockedUnit}}</span>
											<span v-else>{{detail.unit}}</span>
										</template>
									</el-input>
								</div>
								<div class="do-right">
									<p class="p-title">{{$t('redTxt4')}}</p>
									<div class="p-value">{{mybalance | fixedScale(detail.priceScale)}}
										<span>{{detail.acceptUnit}}</span>
									</div>
								</div>
							</div>
							<div class="bottom">
								<el-button type="primary" v-if="detail.step==1 && isActivityInDate" @click="apply">
									{{$t('attend')}}
								</el-button>
								<el-button v-else type="info" disabled>{{$t('attend')}}</el-button>
							</div>
							<img v-if="detail.step==3" src="../assets/photo/completed.png" class="right-state" alt="">
						</div>
						<div class="memo">
							<p style="font-size:14px;margin-bottom:10px;">{{$t('attention')}}：</p>
							<p>{{$t('attentiontxt1')}}</p>
							<p>{{$t('attentiontxt2')}}</p>
							<p>{{$t('attentiontxt3')}}</p><br>
							<p>{{$t('attentiontxt4')}}</p>
						</div>
						<div class="show-qrcode">
							<vue-qr :text="qrUrl" :size='200'></vue-qr>
							<div class="show-qrcode-text">{{$t("etTxt2")}}</div>
						</div>
					</el-col>
				</el-row>
				<el-dialog :visible.sync="dialogVisible" width="360px" custom-class="custom-dialog">
					<div slot="title" class="dialog-title"><b>{{$t('witdia')}}</b></div>
					<div class="dialog-content">
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm"
							label-width="85px">
							<el-form-item :label="$t('phone')" prop="phone" v-show="validPhone">
								<el-input v-model="ruleForm.phone"></el-input>
							</el-form-item>
							<el-form-item :label="$t('chTxt12')" prop="code" v-show="validPhone">
								<el-input v-model="ruleForm.code">
									<template slot="suffix">
										<el-button class="btn btn_yzm" type="primary" :disabled="disabled"
											@click="moblieCode">
											<span v-if="disabled">{{count}} S</span>
											<span v-if="!disabled">{{$t('code2')}}</span>
										</el-button>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('email')" prop="email" v-show="validEmail">
								<el-input v-model="ruleForm.email"></el-input>
							</el-form-item>
							<el-form-item :label="$t('chTxt11')" prop="code2" v-show="validEmail">
								<el-input v-model="ruleForm.code2">
									<template slot="suffix">
										<el-button class="btn btn_yzm" type="primary" :disabled="disabled2"
											@click="emailCode">
											<span v-if="disabled2">{{count2}} S</span>
											<span v-if="!disabled2">{{$t('code2')}}</span>
										</el-button>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item :label="$t('chTxt11')" prop="code2" v-show="validEmail">
								<el-input v-model="ruleForm.code2">
									<template slot="suffix">
										<el-button class="btn btn_yzm" type="primary" :disabled="disabled2"
											@click="emailCode">
											<span v-if="disabled2">{{count2}} S</span>
											<span v-if="!disabled2">{{$t('code2')}}</span>
										</el-button>
									</template>
								</el-input>
							</el-form-item>
						</el-form>
					</div>
					<div slot="footer" class="dialog-footer" @click="handleSubmit('ruleForm')">
						<el-button type="primary">{{$t('submit')}}</el-button>
					</div>
				</el-dialog>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		activityDetail,
		getmemberrecords,
		activityCode,
		activityAttend
	} from '@/api/api/active'
	import {
		assetWallet,
		securitySet,
	} from '@/api/api/user'
	import vueQr from 'vue-qr'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
			vueQr
		},
		data() {
			return {
				mybalance: 0, // 我的可用余额
				myHoldAmount: 0,
				attendAmount: 0,
				detail: {},
				myRecordList: [],
				qrUrl: '',
				isActivityInDate: false, // 活动是否可参加
				isLogin: false,
				dialogVisible: false,
				validEmail: false,
				validPhone: false,
				count: 60,
				disabled: false,
				count2: 60,
				disabled2: false,
				ruleForm: {},
				rules: {
					phone: [{
						required: this.validPhone,
						message: this.$t("telerr"),
						trigger: "blur"
					}],
					code: [{
						required: this.validPhone,
						message: this.$t("codeerr"),
						trigger: "change"
					}],
					email: [{
						required: this.validEmail,
						type: "email",
						message: this.$t("emailerr"),
						trigger: "blur"
					}],
					code2: [{
						required: this.validEmail,
						message: this.$t("codeerr"),
						trigger: "change"
					}]
				}
			};
		},
		created() {
			this.isLogin = this.$store.state.isLogin
			this.qrUrl = window.location.href
		},
		mounted() {
			this.getInfo()
		},
		methods: {
			getInfo() {
				let id = this.$route.params.id
				activityDetail({
					id: id
				}).then(res => {
					console.log(res)
					if (res.code == 0) {
						this.detail = res.data
						if (this.detail.type == 3) {
							if (this.detail.step == 1) {
								this.detail.progress = 50;
							} else if (this.detail.step == 2) {
								this.detail.progress = 75;
							} else if (this.detail.step == 3) {
								this.detail.progress = 100;
							} else {
								this.detail.progress = 0;
							}
						}

						if (this.isLogin) {
							this.getWallet();
							this.getMyRecords();
							this.getMember();
						}

						var currentDateStr = this.$moment(new Date().getTime()).format("YYYY-MM-DD HH:mm:ss");
						if (currentDateStr >= this.detail.startTime && currentDateStr <= this.detail.endTime) {
							this.isActivityInDate = true;
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			getMyRecords() {
				let id = this.$route.params.id
				getmemberrecords({
					activityId: id
				}).then(res => {
					this.myRecordList = res.data;
					this.myHoldAmount = 0;
					if (this.detail.type == 3) {
						for (var i = 0; i < this.myRecordList.length; i++) {
							this.myHoldAmount += this.myRecordList[i].freezeAmount;
						}
					}
				});
			},
			apply() {

				var self = this;
				if (this.detail.type == 1 || this.detail.type == 2) {
					this.$router.push("/exchange/" + this.detail.unit.toLowerCase() + "_" + this.detail.acceptUnit
						.toLowerCase());
					return;
				}
				if (!this.isLogin) {
					this.$notify.error({
						title: this.$t("czTxt9"),
						message: this.$t("logintip")
					});
					setTimeout(function() {
						self.$router.push("/login");
					}, 3000);
				} else {
					this.attendClick();
				}
			},
			attendClick() {
				// 输入检查
				if (!this.attendAmount) {
					if (this.detail.type == 3)
						this.$notify.error({
							title: this.$t("apiTxt2"),
							message: this.$t("pleaseinputholdamount")
						});
					if (this.detail.type == 4)
						this.$notify.error({
							title: this.$t("apiTxt2"),
							message: this.$t("pleaseinputamount")
						});

					return;
				}
				// 大于0
				if (parseFloat(this.attendAmount) == 0) {
					return;
				}
				// 余额检查
				if (this.detail.type == 4) {
					if (parseFloat(this.attendAmount) * this.detail.price > this.mybalance) {
						this.$notify.error({
							title: this.$t("common.tip"),
							message: this.detail.acceptUnit + " " + this.$t("balancenotenough2")
						});
						return;
					}
				}
				// 云矿机需整数购买
				if (this.detail.type == 5 || this.detail.type == 6) {
					if (!(/(^[1-9]\d*$)/.test(this.attendAmount))) {
						this.$notify.error({
							title: this.$t("apiTxt2"),
							message: this.detail.acceptUnit + " " + this.$t("caderr5")
						});
						return;
					}
				}
				// 限购次数、限购量检查
				var temAlreadyAttendAmount = 0;
				var temAlreadyAttendTimes = this.myRecordList.length;

				for (var i = 0; i < this.myRecordList.length; i++) {
					if (this.detail.type == 3) {
						temAlreadyAttendAmount += this.myRecordList[i].freezeAmount;
					} else if (this.detail.type == 4) {
						temAlreadyAttendAmount += this.myRecordList[i].amount;
					}
				}

				// 最小起兑量检查
				if (this.detail.minLimitAmout > 0 && this.attendAmount < this.detail.minLimitAmout) {
					this.$notify.error({
						title: this.$t("apiTxt2"),
						message: this.$t("minlimitamountfailed")
					});
					return;
				}

				// 最大兑换量检查
				if (this.detail.maxLimitAmout > 0 && ((temAlreadyAttendAmount + parseFloat(this.attendAmount)) > this
						.detail.maxLimitAmout)) {
					this.$notify.error({
						title: this.$t("apiTxt2"),
						message: this.$t("maxlimitamountfailed")
					});
					return;
				}

				// 个人限购次数检查
				if (this.detail.limitTimes > 0 && (temAlreadyAttendTimes + 1) > this.detail.limitTimes) {
					this.$notify.error({
						title: this.$t("apiTxt2"),
						message: this.$t("limittimesfailed")
					});
					return;
				}

				// 显示安全验证
				this.dialogVisible = true;
			},
			getWallet() {
				assetWallet({
					symbol: this.detail.acceptUnit
				}).then(res => {
					this.mybalance = res.data.balance || "";
				})
			},
			moblieCode() {
        var that = this;
				activityCode().then(res => {
					if (res.code == 0) {
						this.$message.success(res.message);
						this.disabled = true
            that.count = 60;
						let interval = window.setInterval(function() {
							if (that.count-- <= 0) {
                that.disabled = false;
								window.clearInterval(interval);
							}
						}, 1000);
					} else {
						this.$message.error(res.message);
					}
				})
			},
			emailCode() {
        var that = this;
				activityCode().then(res => {
					if (res.code == 0) {
						this.$message.success(res.message);
						this.disabled2 = true
            that.count2 = 60;
						let interval = window.setInterval(function() {
							if (that.count2-- <= 0) {
                that.disabled2 = false;
								window.clearInterval(interval);
							}
						}, 1000);
					} else {
						this.$message.error(res.message);
					}
				})
			},
			getMember() {
				//获取个人安全信息
				securitySet().then(res => {
					if (res.code == 0) {
						console.log(res)
						if (res.data.mobilePhone) {
							this.ruleForm.phone = res.data.mobilePhone;
							this.validPhone = true;
							this.validEmail = false;
						} else {
							this.ruleForm.email = res.data.email;
							this.validPhone = false;
							this.validEmail = true;
						}
					} else {
						this.$message.error(res.message);
					}
				})
			},
			handleSubmit(name) {
				this.$refs[name].validate(valid => {
					if (valid) {
						this.submit();
					} else {
						this.$Message.error(this.$t("commitfailed"));
					}
				});
			},
			submit() {
				let param = {};
				param["amount"] = this.attendAmount;
				param["activityId"] = this.$route.params.id;
				if (this.validPhone) {
					param["aims"] = this.ruleForm.phone;
					param["code"] = this.ruleForm.code;
				} else {
					param["aims"] = this.ruleForm.email;
					param["code"] = this.ruleForm.code2;
				}
				activityAttend(param).then(res => {
					if (res.code == 0) {
						this.$notify.success({
							title: this.$t("apiTxt2"),
							message: res.message
						});
						this.dialogVisible = false;
						this.getInfo();
						this.attendAmount = 0;
					} else {
						this.$notify.error({
							title: this.$t("apiTxt2"),
							message: res.message
						});
					}
				})
			}
		},
		filters: {
			fixedScale: function(value, scale) {
				if (value != undefined && value != null && value != "") {
					return value.toFixed(scale);
				}
				return 0;
			}
		}
	}
</script>
